<template>
  <div>
    <h3>{{ data.title }}</h3>
    <div v-html="data.description"></div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
  // mounted() {
  //   this.$store.dispatch("getFileToSend",this.data);
  // }
};
</script>
