<template>
  <div class="column is-12">
    <div
      v-bind:style="{
        backgroundImage: 'url(' + introduction.imageUrl + ')'
      }"
      class="image-background"
    >
      <h3 class="is-size-3">
        <b>{{ introduction.title }}</b>
      </h3>
    </div>
    <div v-html="introduction.rules"></div>
  </div>
</template>
<script>
export default {
  props: {
    introduction: {
      default: () => {}
    }
  }
};
</script>
<style>
.image-background {
  width: 100%;
  height: auto;
  text-align: center;
  color: black;
}
</style>
