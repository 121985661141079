var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-multiline header-column"},[_c('div',{staticClass:"column is-2-desktop tugas-left",on:{"click":function($event){return _vm.changeQuestionNo(0, 'section')}}},[_vm._v(" "+_vm._s(_vm.data.section[0].question)+" ")]),_c('div',{staticClass:"is-hidden-mobile",class:{
          'column is-4-desktop tugas-center': _vm.isTugasActive,
          'column is-1-desktop tugas-center': !_vm.isTugasActive
        },on:{"click":function($event){return _vm.changeSize()}}},[(_vm.isTugasActive)?_c('span',[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_vm._v(" TUGAS SAUDARA ")])],1):_c('span',[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_vm._v("TUGAS")])],1)]),_c('div',{class:{
          'column is-6-desktop tugas-right': _vm.isTugasActive,
          'column is-9-desktop tugas-right': !_vm.isTugasActive
        }},[_vm._v(" Lembar Jawab ")])])]),_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-2-desktop content-left"},[_c('div',{staticClass:"\n            section-title text-active\n          "},[_vm._v(" Data Pendukung Soal ")]),_vm._l((_vm.data.subsection),function(subsection,index){return _c('div',{key:index,staticClass:"sub-section"},[(!_vm.isQuestionActive && index == _vm.questionNo)?_c('div',{staticClass:"text-active",on:{"click":function($event){return _vm.changeQuestionNo(index, 'subsection')}}},[_vm._v(" "+_vm._s(subsection.title)+" ")]):_c('div',{staticClass:"text-inactive",on:{"click":function($event){return _vm.changeQuestionNo(index, 'subsection')}}},[_vm._v(" "+_vm._s(subsection.title)+" ")])])})],2),_c('div',{class:{
          'column is-4-desktop content-center': _vm.isTugasActive,
          'column is-1-desktop content-center': !_vm.isTugasActive
        }},[(!_vm.isTugasActive)?_c('div',[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('b-icon',{attrs:{"icon":"arrow-right","size":"is-large"}})],1)],1):_c('div',[(_vm.questionType == 'subsection')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.subsection[_vm.questionNo].question)}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.section[_vm.questionNo].content)}})])]),_c('div',{class:{
          'column is-6-desktop content-right': _vm.isTugasActive,
          'column is-9-desktop content-right': !_vm.isTugasActive
        }},[_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }