<template>
  <div class="unauthorized columns is-multiline">
    <div class="column is-12">
      <b-field grouped position="is-centered">
        <h1>Unauthorized!</h1>
      </b-field>
    </div>
    <div class="column is-12">
      <b-field grouped position="is-centered">
        <h2>You are not allowed to enter here</h2>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnderConstruction',
  data() {
    return {
      isLoading: true,
    }
  },
}
</script>

<style lang="scss">
.construction-sub-title {
  margin: auto;
}

.construction-title {
  margin: auto;
}

.construction-icon {
  margin: auto;
}
h1 {
  font-size: 15vmin;
  margin-bottom: 0;
}
h2 {
  font-size: 5vmin;
  margin-top: 0;
  margin-bottom: 40px;
}
.unauthorized {
  padding-top: 10%;
  width: 100%;
}
</style>
