<template>
  <div class="columns not-found">
    <span class="column is-6">
      <b-field grouped position="is-right">
        <img src="../assets/image/not-found.png" width="200px;" />
      </b-field>
    </span>
    <span class="column is-6 not-found-text">
      <h3 class="is-size-3">SORRY!</h3>
      The assessment you were looking for was not found
    </span>
  </div>
</template>

<script>
export default {
  name: 'UnderConstruction',
  data() {
    return {
      isLoading: true,
    }
  },
}
</script>

<style lang="scss">
.not-found-text {
  padding-top: 5%;
  border-left: 1px solid black;
  padding-left: 5%;
}
.not-found {
  padding-top: 17%;
  height: 100%;
}
</style>
