<template>
  <div class="columns is-multiline content">
    <div class="column is-12 camera-not-found">
      <b-field grouped position="is-centered">
        <img src="../assets/image/cameraNotFound.png" width="250" />
      </b-field>
    </div>
    <div class="column is-12">
      <b-field grouped position="is-centered">
        <h2>Kamera tidak ditemukan!</h2>
      </b-field>
      <b-field grouped position="is-centered">
        <h2>Silahkan cek kembali apakah kamera anda berfungsi</h2>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnderConstruction",
  data() {
    return {
      isLoading: true
    };
  }
};
</script>

<style lang="scss">
.construction-sub-title {
  margin: auto;
}

.construction-title {
  margin: auto;
}

.construction-icon {
  margin: auto;
}
h1 {
  font-size: 15vmin;
  margin-bottom: 0;
}
h2 {
  font-size: 5vmin;
  margin-top: 0;
  margin-bottom: 40px;
}
.camera-not-found {
  padding-top: 6%;
  padding-bottom: 2%;
  width: 100%;
}
</style>
