<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns is-multiline header-column">
        <div
          class="column is-2-desktop tugas-left"
          @click="changeQuestionNo(0, 'section')"
        >
          {{ data.section[0].question }}
        </div>
        <div
          v-bind:class="{
            'column is-4-desktop tugas-center': isTugasActive,
            'column is-1-desktop tugas-center': !isTugasActive
          }"
          class="is-hidden-mobile"
          @click="changeSize()"
        >
          <span v-if="isTugasActive">
            <b-field grouped position="is-centered">
              TUGAS SAUDARA
            </b-field>
          </span>
          <span v-else>
            <b-field grouped position="is-centered">TUGAS</b-field>
          </span>
        </div>
        <div
          v-bind:class="{
            'column is-6-desktop tugas-right': isTugasActive,
            'column is-9-desktop tugas-right': !isTugasActive
          }"
        >
          Lembar Jawab
        </div>
      </div>
    </div>
    <div class="column is-12">
      <div class="columns is-multiline">
        <div class="column is-2-desktop content-left">
          <div
            class="
              section-title text-active
            "
          >
            Data Pendukung Soal
          </div>
          <div
            class="sub-section"
            v-for="(subsection, index) in data.subsection"
            :key="index"
          >
            <div
              class="text-active"
              v-if="!isQuestionActive && index == questionNo"
              @click="changeQuestionNo(index, 'subsection')"
            >
              {{ subsection.title }}
            </div>
            <div
              class="text-inactive"
              v-else
              @click="changeQuestionNo(index, 'subsection')"
            >
              {{ subsection.title }}
            </div>
          </div>
        </div>
        <div
          v-bind:class="{
            'column is-4-desktop content-center': isTugasActive,
            'column is-1-desktop content-center': !isTugasActive
          }"
        >
          <div v-if="!isTugasActive">
            <b-field grouped position="is-centered">
              <b-icon icon="arrow-right" size="is-large"></b-icon>
            </b-field>
          </div>
          <div v-else>
            <div
              v-if="questionType == 'subsection'"
              v-html="data.subsection[questionNo].question"
            ></div>
            <div v-else v-html="data.section[questionNo].content"></div>
          </div>
        </div>
        <div
          v-bind:class="{
            'column is-6-desktop content-right': isTugasActive,
            'column is-9-desktop content-right': !isTugasActive
          }"
        >
          <ckeditor v-model="data.answer" :config="editorConfig" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import debounce from "lodash/debounce";

export default {
  props: {
    data: {
      default: () => {}
    }
  },
  data() {
    return {
      editorConfig: {
        extraAllowedContent: "div[class]",
        allowedContent: true,
        extraPlugins: "justify,font",
        height: "20em",
        resize_enabled: false
      },
      isTugasActive: true,
      questionNo: 0,
      questionType: "section",
      isQuestionActive: true
    };
  },
  watch: {
    "data.answer": debounce(function() {
      this.submit();
    }, 500)
  },
  methods: {
    changeSize() {
      this.isTugasActive = !this.isTugasActive;
    },
    changeQuestionNo(index, section) {
      this.questionType = section;
      this.questionNo = index;
      if (section == "subsection") {
        this.isQuestionActive = false;
      } else {
        this.isQuestionActive = true;
      }
    },
    submit() {
      var today = new Date();

      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      var dateTime = date + " " + time;
      this.data.time = dateTime;
      this.$emit("clicked");
    }
  }
};
</script>
<style>
.arrow-center {
  background-color: #dbdbdb;
  font-size: 16px;
  padding-top: 0.5%;
  height: 7vh;
  font-weight: 600;
  border-right: 1px solid #acacac;
}

@media screen and (min-width: 1216px) {
  .content-left {
    height: 76vh;
    background-color: #f5f5f5;
    border-left: 1px solid #acacac;
  }

  .content-center {
    max-height: 76vh;
    border-right: 1px solid #acacac;
  }
  .content-right {
    max-height: 76vh;
    border-left: 1px solid #acacac;
  }
}
@media screen and (min-width: 1408px) {
  .content-left {
    height: 78vh;
    background-color: #f5f5f5;
    border-left: 1px solid #acacac;
  }

  .content-center {
    max-height: 78vh;
    border-right: 1px solid #acacac;
  }
  .content-right {
    max-height: 78vh;
    border-left: 1px solid #acacac;
  }
}
.section-title {
  padding-left: 7%;
  font-weight: 600;
  cursor: pointer;
}

.sub-section {
  padding-top: 5%;
  padding-left: 13%;
  font-weight: 600;
  cursor: pointer;
}

.text-inactive {
  color: #a2a2a2;
}

.text-acitve {
  color: black;
}

.tugas-left {
  background-color: #00a3a3;
  color: white;
  font-size: 16px;
  padding-left: 2%;
  padding-top: 0.7%;
  height: 7vh;
  cursor: pointer;
}
.tugas-center {
  background-color: #dbdbdb;
  font-size: 16px;
  padding-top: 0.7%;
  height: 7vh;
  font-weight: 600;
  border-right: 1px solid #acacac;
  cursor: pointer;
}
.tugas-right {
  background-color: #dbdbdb;
  font-size: 16px;
  height: 7vh;
  font-weight: 600;
  border-left: 1px solid #acacac;
}
@media screen and (min-width: 1024px) {
  .tugas-center {
    padding-top: 0.7%;
  }
  .tugas-left {
    padding-top: 0.7%;
  }
  .tugas-right {
    padding-top: 0.7%;
  }
}
@media screen and (min-width: 1408px) {
  .tugas-center {
    padding-top: 1%;
    font-size: 20px;
  }
  .tugas-left {
    padding-top: 1%;
    font-size: 20px;
  }
  .tugas-right {
    padding-top: 1%;
    font-size: 20px;
  }
}
</style>
