<template>
  <div class="columns">
    <div class="column is-12">
      <div>
        {{ data.title }}
        <div v-html="data.description"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
<style>
/* .image-background {
  width: 100%;
  height: 5rem;
  text-align: center;
  color: white;
} */
</style>
